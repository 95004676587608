// FADE TO TOP
var $animation_elements = $('.animated');
var $window = $(window);

function check_if_in_view() {
  var window_height = $window.height();
  var window_top_position = $window.scrollTop();
  var window_bottom_position = (window_top_position + window_height);

  $.each($animation_elements, function() {
    var $element = $(this);
    var element_height = $element.outerHeight();
    var element_top_position = $element.offset().top;
    var element_bottom_position = (element_top_position + element_height);

    //check to see if this current container is within viewport
    if ((element_bottom_position >= window_top_position) &&
      (element_top_position <= window_bottom_position)) {
      $element.addClass('active');
    }
    //else {
      //$element.removeClass('active');
    //}
  });
}

$window.on('scroll resize', check_if_in_view);
$window.trigger('scroll');

// IMAGE REPLACE - img src to bg css
function replaceSrc() {

     $('.js-replace-img').each(function() {

       var src = $(this).find("img").attr("src");
       $(this).css("background-image","url("+src+")");
     });
    };

    jQuery(function() {
     if($(".js-replace-img")) {
       replaceSrc();
     }
    });


(function(){

  var s = {

    menuState: function() {

      $(this).toggleClass("cross");
      $(".main-nav-wrapper").toggleClass("open");
      $('.nav-header').toggleClass('z-index');

    },

    headerHeight: function() {

      if($(window).scrollTop() > 0) {

        return $('.main-header').height();
      } else {

        return $('.main-header').height() - 24;
      }
    },

    headerPosition: function() {

      var top = $(window).scrollTop();

      if(top > 72) {



      } else {




      }
    },

    events: function() {
      $(window).scroll(this.headerPosition);
      $("#btnMobile").on("click",this.menuState);
    },

    init: function() {

      this.headerPosition();
      this.events();
    }

  }

  s.init();

})();
$('.main-nav-wrapper').click(function(e){
      $('.main-nav-wrapper').removeClass('open');
      $('.btn-menu').removeClass('cross');

})
var wrap=$(window);
if ($('.nav-header').hasClass('js-event ') ){
  wrap.on("scroll", function() {
  console.log(wrap.scrollTop());
  if (wrap.scrollTop() > Math.floor($(".nav-header p").offset().top)-80) {
    $('.nav-header').addClass("block-nav");

  }
  if( wrap.scrollTop() == 0 ){
    $('.nav-header').removeClass("block-nav");
  } else if (wrap.scrollTop() > 80){
    $('.nav-header').addClass("block-nav");
  }


});

}

/*
To activate the parallax set parallax-container on parent and set parallax-item on each child...
data-velocity = speed
*/
function parallax(){

  $(window).scroll(function (event) {
      var scroll = $(window).scrollTop();
      // Do something
      var $window = $(window);
      var window_height = $window.height();
      var window_top_position = $window.scrollTop();
      var window_bottom_position = (window_top_position + window_height);
      var $element_to_paralax = $(".parallax-container");
      $.each($element_to_paralax, function() {
        console.log($(this));
      console.log($('.parallax-item').attr('data-velocity'));
      var $element = $(this);
      var element_height = $element.outerHeight();
      var element_top_position = $element.offset().top;
      var element_bottom_position = $element.offset().bottom;

      var element_bottom_position = (element_top_position + element_height);

      //check to see if this current container is within viewport

      if ((element_bottom_position >= window_top_position) &&
        (element_top_position <= window_bottom_position)) {
          console.log('Here');
          $.each($(this).find('.parallax-item'),function(){
            $(this).addClass('parallax-it');
            parallaxIt($element,window_top_position, $(this), $(this).attr('data-velocity'));
          })


      }
    });

  });


}
var ua = window.navigator.userAgent;
var msie = ua.indexOf("MSIE ");
if (Modernizr.touch) {
}
else if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)){

}
else{
  parallax();
}





function parallaxIt(element,e, target, movement) {
  var $this = element;
  var relY = e - $this.offset().top;

  TweenMax.to(target, 1, {
    y: (relY - $this.height() / 2) / $this.height() * movement,

  });
}



(function(){
  // setup your carousels as you normally would using JS
  // or via data attributes according to the documentation
  // https://getbootstrap.com/javascript/#carousel
  $('#carousel-cares').carousel({ interval: 2000 });
}());

(function(){
  $('.carousel-cares .item').each(function(){
    var itemToClone = $(this);

    for (var i=1;i<4;i++) {
      itemToClone = itemToClone.next();

      // wrap around if at end of item collection
      if (!itemToClone.length) {
        itemToClone = $(this).siblings(':first');
      }

      // grab item, clone, add marker class, add to collection
      itemToClone.children(':first-child').clone()
        .addClass("cloneditem-"+(i))
        .appendTo($(this));
    }
  });
}());


//Slideshow Single Cares
function initSlider(){
  var i = 1 ;
  var totalSlide = $('.slides li').length;
  var autoPlay = window.setInterval(autoPlay, 5000);


  $('.slides li').eq(0).addClass('current');
  var index = $(".slides li.current").index();
  setInterval(function(){
    index++;

        if (index >=totalSlide){
          // RESET I
          index = 0;
        }
        $('.slides li').removeClass('current');
        $('.slides li').eq(index).addClass('current');

  }, 5000);

  $('.flexslider .controllers .control').click(function(e){
    window.clearInterval(autoPlay);
    autoPlay = window.setInterval(autoPlay, 8000);

    if ( $(this).hasClass('next') ){
      index++;

      if (index >=totalSlide){
        // RESET I
        index = 0;
      }
      $('.slides li').removeClass('current');
      $('.slides li').eq(index).addClass('current');
    }

     if ( $(this).hasClass('prev') ){
      index--;

      if (index < 0 ){
        // RESET I
        index=totalSlide-1;
        $('.slides li').removeClass('current');
              console.log(index);
        $('.slides li').eq(totalSlide-1).addClass('current');
      }
      else{
        $('.slides li').removeClass('current');
        $('.slides li').eq(index).addClass('current');
      }

    }

  });


}

initSlider();

// FANCYBOX LIGHT BOX GALLERY
$(".gallery-slide a").fancybox({
  animationEffect : 'fade'
}).attr('data-fancybox', 'groupFancyBox');


$('.btnNavAccount').click(function(e){
  $('.woocommerce-MyAccount-navigation').toggleClass('open');
  $(this).toggleClass('open');
});

$(".woocommerce-MyAccount-navigation").click(function(e){
    $('.woocommerce-MyAccount-navigation').removeClass('open');

});
