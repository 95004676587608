(function() {
  var c = {

    carrouselInit: function() {

      $(".js-carrousel").each(function() {

        var slideshow = $(this);
        slideshow.attr("data-current",0);
        var type = slideshow.attr("data-type");
        var loop = slideshow.attr("data-loop");
        var effect = slideshow.attr("data-effect");
        var wrapper = slideshow.find(".js-carrousel-wrapper");
        var slides = slideshow.find(".js-carrousel-slide");
        var controller = slideshow.find(".js-carrousel-controllers");
        var cType = controller.attr("data-type");

        c.slidesInit(wrapper,slides,type,loop,effect);
        c.controllerInit(slideshow,controller,cType);
      });

      if($('.js-carrousel-modal')) {

        $(".js-carrousel-modal").each(function() {
          var t = $(this);
          var close = $("<button>close</button>").addClass("js-carrousel-close");
          close.click(function() {t.removeClass("show")});
          $(this).append(close);
        });
      }
    },

    controllerInit: function(slideshow,controller,type) {

      if(type == "switch") {

        var next = $("<span></span>").addClass("js-carrousel-next").text("next");
        var prev = $("<span></span>").addClass("js-carrousel-prev").text("previous");
        controller.append(next,prev);
        $(next).on("click",function() {c.switching(slideshow,'next');});
        $(prev).on("click",function() {c.switching(slideshow,'prev');});
        console.log(c);
        if (Modernizr.touch) {
        $.getScript( "https://cdnjs.cloudflare.com/ajax/libs/hammer.js/2.0.8/hammer.min.js", function( data, textStatus, jqxhr ) {
        console.log( "Load was performed." );
        // create a simple instance
        // by default, it only adds horizontal recognizers
            var wrapper = $('.js-carrousel-wrapper');
           var hammertime = new Hammer(wrapper[0]);
            hammertime.on("swipeleft", function(eventObject) {
                    c.switching(slideshow,'next');
            });
            hammertime.on("swiperight", function(eventObject) {
                    c.switching(slideshow,'prev');
            });

        });
 

  
      }

      }
    },

    slidesInit: function(wrapper,slides,type,loop,effect) {

      slides.each(function(i) {

        var img = $(this).find("img");
        var imgPath = img.attr("src");
        slides.eq(0).addClass('current');
        slides.eq(-1).addClass('fade-out');

        if(effect == "zoom") {

          var span = $("<span></span>");
          span.css("background-image","url("+imgPath+")");
          $(this).find(".js-carrousel-image").append(span);

        } else {

          $(this).find(".js-carrousel-image").css("background-image","url("+imgPath+")");
        }

        if(type === "multiple") {

          var k = 100*i;
          $(this).css("transform",'translate('+ k +'%,0)');
        }
      });

      if(loop) {

        var first = slides.eq(0).clone();
        var last = slides.eq(-1).clone();
        first.css("transform",'translate('+ slides.length * 100 +'%,0)');
        last.css("transform",'translate('+ -100 +'%,0)');
        wrapper.prepend(last);
        wrapper.append(first);
      }
    },

    updatePosition: function(slideshow) {

      var k = slideshow.attr('data-current');
      var slides = slideshow.find(".js-carrousel-slide");
      if ($('.js-carrousel').hasClass('template-carrousel')){
        var title = slideshow.find('.current').attr("data-title");
        var content = slideshow.find('.current').attr("data-content");
        var link = slideshow.find('.current').attr("data-link");

        updateContent(title,content,link);
      }

      slides.each(function(i) {

        $(this).css("transform","translate("+ ((i*100) - 100) +"%,0)");
      });

    },

    switching: function(slideshow,d) {

      var current = parseInt(slideshow.attr('data-current'));
      var slides = slideshow.find(".js-carrousel-slide");
      var wrapper = slideshow.find(".js-carrousel-wrapper");
      var k;

    


      if(d === "next") {
       
        slides.removeClass('fade-out');
        slides.removeClass('current');

        console.log('next');
        k = -1;

        var first = slides.eq(2).clone();
        slides.eq(1).addClass('fade-out');

        slides.eq(0).remove();
        first.css("transform",'translate('+ slides.length * 100 +'%,0)');
        first.fadeOut();
        slides.eq(2).addClass('current');

        wrapper.append(first);


      } else {



        k = 1;
        slides.removeClass('fade-out');
        slides.removeClass('current')


        var last = slides.eq(-3).clone();
        last.addClass('fade-out');
        slides.eq(-1).remove();

        last.css("transform",'translate('+ -100 +'%,0)');
        last.fadeOut();

        wrapper.prepend(last);
        slides.eq(0).addClass('current');

       
      }

      current += k;
      slideshow.attr("data-current",current);

      c.updatePosition(slideshow);
    },

    events: function() {

      if($(".js-carrousel-open")) {

        $(".js-carrousel-open").click(function() {
          var carrousel = $(this).attr("data-carrousel");
          $(".js-carrousel-modal").each(function(){
            if( $(this).attr("data-carrousel") == carrousel) {

              $(this).addClass("show");
            }
          });
        });
      }
    },

    init: function() {

      this.events();
      this.carrouselInit();
    }
  };

  c.init();
})();

function updateContent(title, content,link){
  var titleToreplace = $('.carrousel-content').find('h2');
  var contentToreplace = $('.carrousel-content').find('p');
  var linkToUpdate = $('.carrousel-content').find('.cares-cta')


  var timeAnimation = 600;
    titleToreplace.fadeOut(timeAnimation);
    contentToreplace.fadeOut(timeAnimation);
    linkToUpdate.fadeOut(timeAnimation);
    setTimeout(function() {
        $(titleToreplace).html(title);  
        $(contentToreplace).html(content);  
        $(linkToUpdate).find('a').attr('href',link);

        titleToreplace.fadeIn(timeAnimation);
        contentToreplace.fadeIn(timeAnimation);
        linkToUpdate.fadeIn(timeAnimation);

    }, timeAnimation);


}
